"use client";

import { Currency, Language, PopupTags, SiteContentScopes } from "@finbackoffice/enums";
import {
    AffiliateTrackerProvider,
    AuthProvider,
    BannersProvider,
    BetSlipProviderV2,
    BrowserStorageProvider,
    CasinoFavoritesProvider,
    ChatProvider,
    ChatServiceProvider,
    ClientProvider,
    ConfigProviderV2,
    CrmProviderV2,
    ExchangeRatesProviderV2,
    FeedProvider,
    I18nextProvider,
    LocaleProvider,
    ModalsProviderV2,
    PopupBannersProvider,
    prepareConfigsV2,
    RuntimeConfigProvider,
    SiteNotificationsProvider,
    UserAccountProviderV2,
} from "@finbackoffice/site-core";
import { captureException } from "@sentry/nextjs";
import { FC, PropsWithChildren } from "react";
import { useSearchParams } from "next/navigation";
import { IRuntimeConfig } from "@finbackoffice/fe-core";
import {
    ICasinoFavoriteResponse,
    IProfileResponse,
    ISiteConfigs,
} from "@finbackoffice/clientbff-client";
import ChatScript from "components/base/external-scripts/ChatScript";
import {
    CasinoToolsProvider,
    InitialDataProvider,
    MarketUpdatesProvider,
    NotificationProvider,
    PrematchProvider,
    SIRWidgetProvider,
    SportResultsProvider,
    WebsocketProvider,
} from "contexts";

type IProvidersProps = {
    locale: Language;
    translations: Record<string, any>;
    uuid: string;
    appConfig: IRuntimeConfig;
    profile: IProfileResponse | null;
    casinoFavoriteGames: ICasinoFavoriteResponse[] | null;
    configs: ISiteConfigs;
    token: string;
};

export const Providers: FC<PropsWithChildren<IProvidersProps>> = (props) => {
    const searchParams = useSearchParams();
    const ignoreNotifications = searchParams!.get("isTest") === "true";
    const commonSiteConfig = props.appConfig.COMMON_SITE_CONFIGS;
    const siteConfig = props.appConfig.SITE_CONFIG;

    const mergedConfigs = prepareConfigsV2(
        props.configs,
        siteConfig.accountFields,
        siteConfig.signupFields,
    );

    return (
        <LocaleProvider locale={props.locale}>
            <I18nextProvider translations={props.translations}>
                <BrowserStorageProvider>
                    <AuthProvider initialJwt={props.token}>
                        <ClientProvider captureException={captureException}>
                            <RuntimeConfigProvider config={props.appConfig}>
                                <NotificationProvider>
                                    <UserAccountProviderV2
                                        initialProfile={props.profile}
                                        uuid={props.uuid}>
                                        <ConfigProviderV2
                                            defaultConfig={mergedConfigs}
                                            accountFields={siteConfig.accountFields}
                                            signupFields={siteConfig.signupFields}
                                            defaultCountry={commonSiteConfig.country.default}
                                            walletConfigs={commonSiteConfig.wallet}>
                                            <ModalsProviderV2>
                                                <PopupBannersProvider
                                                    device={PopupTags.Mobile}
                                                    popups={[]}
                                                    ignore={ignoreNotifications}>
                                                    <ExchangeRatesProviderV2
                                                        fxCurrency={Currency.BRL}>
                                                        <WebsocketProvider
                                                            clientWsEndpoint={
                                                                props.appConfig.CLIENT_WS_ENDPOINT
                                                            }>
                                                            <FeedProvider>
                                                                <InitialDataProvider>
                                                                    <CasinoFavoritesProvider
                                                                        favoriteGames={
                                                                            props.casinoFavoriteGames ||
                                                                            null
                                                                        }
                                                                        mobile>
                                                                        <CasinoToolsProvider>
                                                                            <BannersProvider
                                                                                device={
                                                                                    SiteContentScopes.Mobile
                                                                                }>
                                                                                <ChatProvider>
                                                                                    <AffiliateTrackerProvider>
                                                                                        <ChatServiceProvider
                                                                                            script={
                                                                                                <ChatScript
                                                                                                    isUserLoggedIn={
                                                                                                        props
                                                                                                            .token
                                                                                                            .length >
                                                                                                        0
                                                                                                    }
                                                                                                />
                                                                                            }>
                                                                                            <CrmProviderV2>
                                                                                                <SiteNotificationsProvider
                                                                                                    ignore={
                                                                                                        ignoreNotifications
                                                                                                    }
                                                                                                    appName={
                                                                                                        commonSiteConfig.appName
                                                                                                    }>
                                                                                                    <BetSlipProviderV2>
                                                                                                        <MarketUpdatesProvider>
                                                                                                            <SportResultsProvider>
                                                                                                                <SIRWidgetProvider>
                                                                                                                    <PrematchProvider>
                                                                                                                        {
                                                                                                                            props.children
                                                                                                                        }
                                                                                                                    </PrematchProvider>
                                                                                                                </SIRWidgetProvider>
                                                                                                            </SportResultsProvider>
                                                                                                        </MarketUpdatesProvider>
                                                                                                    </BetSlipProviderV2>
                                                                                                </SiteNotificationsProvider>
                                                                                            </CrmProviderV2>
                                                                                        </ChatServiceProvider>
                                                                                    </AffiliateTrackerProvider>
                                                                                </ChatProvider>
                                                                            </BannersProvider>
                                                                        </CasinoToolsProvider>
                                                                    </CasinoFavoritesProvider>
                                                                </InitialDataProvider>
                                                            </FeedProvider>
                                                        </WebsocketProvider>
                                                    </ExchangeRatesProviderV2>
                                                </PopupBannersProvider>
                                            </ModalsProviderV2>
                                        </ConfigProviderV2>
                                    </UserAccountProviderV2>
                                </NotificationProvider>
                            </RuntimeConfigProvider>
                        </ClientProvider>
                    </AuthProvider>
                </BrowserStorageProvider>
            </I18nextProvider>
        </LocaleProvider>
    );
};
