"use client";

import { FC, useContext, useEffect } from "react";
import { BannersContext, PopupBannersContext } from "@finbackoffice/site-core";
import {
    IBannerResponse,
    IPopupResponse,
    IUserInterfaceTranslationResponse,
} from "@finbackoffice/clientbff-client";
import { TranslationScopes } from "@finbackoffice/enums";
import { useSetI18nextResources } from "../hooks";

export const Page: FC<{
    children: React.ReactNode;
    translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>;
    banners?: IBannerResponse[];
    popupBanners?: IPopupResponse[];
}> = ({ children, translations, banners, popupBanners }) => {
    const { setTranslationResources } = useSetI18nextResources();
    const { setBannersState } = useContext(BannersContext);
    const { setPopups } = useContext(PopupBannersContext);

    useEffect(() => {
        setBannersState(banners || []);
    }, [banners, setBannersState]);

    useEffect(() => {
        setPopups(popupBanners || []);
    }, [popupBanners, setPopups]);

    useEffect(() => {
        setTranslationResources(translations);
    }, [translations, setTranslationResources]);

    return <>{children}</>;
};
